<div class="wrapper">
  <div class="sidebar" data-color="white" data-active-color="danger">
    <app-sidebar-cmp></app-sidebar-cmp>
  </div>

  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>

    <div class="content">
      <h1>{{ title }}</h1>

      <div>
        <input #userHandle type="text" placeholder="Telegram User Handle">
        <input #peer type="text" placeholder="Peer (e.g., chatgpt1)">
        <input #msgId type="number" placeholder="Message ID (e.g., 45)">
        <button (click)="getUserAndDiscussionInfo(userHandle.value, peer.value, +msgId.value)">Get Info</button>
        <!-- Note the "+" before msgId.value to convert it to a number -->
      </div>

      <div *ngIf="errorMessage">
        <p style="color: red;">{{ errorMessage }}</p>
      </div>

      <div *ngIf="userInfo">
        <h2>User Info</h2>
        <p><strong>Username:</strong> {{ userInfo.username }}</p>
        <p><strong>Full Name:</strong> {{ userInfo.full_name }}</p>
        <p><strong>Bio:</strong> {{ userInfo.bio }}</p>
        <p><strong>ID:</strong> {{ userInfo.id }}</p>
        <p><strong>Phone:</strong> {{ userInfo.phone }}</p>
        <img *ngIf="userInfo.photo" [src]="userInfo.photo" alt="{{ userInfo.full_name }}'s profile picture">
      </div>

      <div *ngIf="discussionInfo">
        <h2>Discussion Info</h2>
        <pre>{{ discussionInfo | json }}</pre> <!-- Display discussion info as JSON -->
      </div>
    </div>
  </div>
</div>
