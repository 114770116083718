<div class="sidebar-wrapper">
    <div class="logo">
      <a href="http://canpostthis.com" class="simple-text logo-normal">
        <img src="assets/paper-design/logo.png">
      </a>
    </div>
      <ul class="nav">
        <li class="active">
            <a routerLink="/dashboard" routerLinkActive="active">
                <p>Dashboard</p>
            </a>
        </li>

        <li>
            <a>
                <p>Account</p>
            </a>
        </li>
        <li>
          <a routerLink="/analysis" routerLinkActive="active">Analysis,statistics and tracking
          </a>
        </li>
        <li>
          <a routerLink="/fakenews" routerLinkActive="active">Fake News Identifier</a>
        </li>
        <li>
        <li>
          <a routerLink="/youtube" routerLinkActive="active">Youtube
          </a>
        </li>
        <li>
          <a routerLink="/telegram" routerLinkActive="active">Telegram
          </a>
        </li>
        <li>
          <a routerLink="/x-comp" routerLinkActive="active">X (formerly Twitter)
          </a>
        </li>
        <li>
          <a routerLink="/instagram" routerLinkActive="active">Instagram</a>
        </li>
        <li>
        <a routerLink="/tiktok" routerLinkActive="active">TikTok</a>
      </li>
      <li>
        <a>
            <p>Payments</p>
        </a>
    </li>
    <li>
      <a routerLink="/upload-content" routerLinkActive="active">Upload content
      </a>
    </li>
        <li routerLinkActive="active">
          <a>
              <p>About</p>
          </a>
      </li>
        <li>  
          <a routerLink="/logout" routerLinkActive="active">Logout</a>
        </li>
      
      </ul>
  </div>
  
