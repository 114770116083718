import { Component } from '@angular/core';
import { TelegramService } from '../services/telegram.service';

@Component({
  selector: 'app-telegram',
  templateUrl: './telegram.component.html',
  styleUrls: ['./telegram.component.scss']
})
export class TelegramComponent {
  title: string = 'Identify Telegram Account';
  userInfo: any;
  discussionInfo: any;
  errorMessage: string | null = null;

  constructor(private telegramService: TelegramService) {}

  getUserAndDiscussionInfo(userHandle: string, peer: string, msgId: number): void {
    this.telegramService.getTelegramUserAndDiscussion(userHandle, peer, msgId).subscribe(
      data => {
        console.log('User Info:', data.userInfo);
        console.log('Discussion Info:', data.discussionInfo);
        this.userInfo = data.userInfo;
        this.discussionInfo = data.discussionInfo;
        this.errorMessage = null;
      },
      error => {
        this.errorMessage = 'An error occurred. Please try again later.';
      }
    );
  }
}
