<div class="wrapper">
  <div class="sidebar" data-color="white" data-active-color="danger">
    <app-sidebar-cmp></app-sidebar-cmp>
  </div>

  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>

    <div class="content">
      <h1>TikTok Component</h1>

      <div>
        <input #username type="text" placeholder="TikTok Username">
        <button (click)="getUserInfo(username.value)">Get User Info</button>
      </div>

      <div *ngIf="errorMessage">
        <p style="color: red;">{{ errorMessage }}</p>
      </div>

      <div *ngIf="user">
        <h2>{{ user.nickname }} (&#64;{{ user.uniqueId }})</h2>
        <p><strong>ID:</strong> {{ user.id }}</p>
        <img *ngIf="user.avatarLarger" [src]="user.avatarLarger" alt="{{ user.nickname }}'s profile image">
        <p><strong>Followers:</strong> {{ user.followerCount }}</p>
        <p><strong>Following:</strong> {{ user.followingCount }}</p>
        <p><strong>Bio:</strong> {{ user.signature }}</p>
        <p class="fake-probability" *ngIf="fakeProbability >= 0" style="color: red;">
          {{ probabilityMessage }}
        </p>
      </div>
    </div>
  </div>
</div>
