import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TiktokService {
  private apiUrl = 'https://tiktok82.p.rapidapi.com/getProfile';

  constructor(private http: HttpClient) {}

  getTikTokProfile(username: string): Observable<any> {
    const apiUrl = `${this.apiUrl}?username=${username}`;
    return this.http.get(apiUrl, {
      headers: {
        'x-rapidapi-host': 'tiktok82.p.rapidapi.com',
        'x-rapidapi-key': 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e' // Replace with your RapidAPI key if needed
      }
    }).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
