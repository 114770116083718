import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public apiUrl = environment.apiUrl;
  private clientId = environment.clientId;
  private redirectUri = environment.redirectUri;
  //private redirectUri = 'https://192.168.178.40:4999'; // Redirect URI to your dashboard route
  //private redirectUri = 'https://37.120.189.106:4999'; // Redirect URI to your dashboard route

  //public apiUrl = 'https://37.120.189.106:4999/api'; // Change this to your Flask API URL
  //public apiUrl = 'https://127.0.0.1:4999/api'; // Change this to your Flask API URL
  //private clientId = '780134460535976'; // Replace with your Instagram Client ID
  // switch between dev and prod
  //private redirectUri = 'https://192.168.178.40:4999'; // Redirect URI to your dashboard route
  //private redirectUri = 'https://37.120.189.106:4999'; // Redirect URI to your dashboard route

  constructor(private http: HttpClient) {}

  initiateAuth() {
    const authUrl = `https://api.instagram.com/oauth/authorize?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&response_type=code&scope=user_profile,user_media`;
    window.location.href = authUrl;
  }

  exchangeCode(code: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/exchange-code`, { code });
  }

  getInstagramProfile(username: string): Observable<any> {
    const apiUrl = `https://instagram-scraper-api2.p.rapidapi.com/v1/info?username_or_id_or_url=${username}`;
    return this.http.get(apiUrl, {
      headers: {
        'x-rapidapi-host': 'instagram-scraper-api2.p.rapidapi.com',
        'x-rapidapi-key': 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e' // Replace with your RapidAPI key if needed, otherwise remove this line
      }
    });
  }



  setSession(token: string): void {
    localStorage.setItem('access_token', token);
  }

  getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  isLoggedIn(): boolean {
    return !!this.getToken();
  }

  getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    const token = this.getToken();
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }

  logout() {
    localStorage.removeItem('access_token');
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/login`, { inputEmail: email, inputPassword: password });
  }

  register(email: string, password: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/register`, { inputEmail: email, inputPassword: password });
  }
}
