import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, forkJoin, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TelegramService {
  private apiUrl = 'https://telegram92.p.rapidapi.com/api';

  constructor(private http: HttpClient) {}

  // Method to get user info
  getTelegramUserInfo(userHandle: string): Observable<any> {
    const apiUrl = `${this.apiUrl}/info?user=${userHandle}`;
    return this.http.get(apiUrl, {
      headers: {
        'x-rapidapi-host': 'telegram92.p.rapidapi.com',
        'x-rapidapi-key': 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e' // Replace with your RapidAPI key
      }
    }).pipe(
      catchError(this.handleError)
    );
  }

  // Method to get discussion info
  getTelegramDiscussion(peer: string, msgId: number, limit: number = 10, offset: number = 0, offsetId: number = 0): Observable<any> {
    const params = {
      peer: peer,
      msg_id: msgId.toString(),
      limit: limit.toString(),
      offset: offset.toString(),
      offset_id: offsetId.toString(),
    };

    return this.http.get(`${this.apiUrl}/discuss`, {
      params: params,
      headers: {
        'x-rapidapi-host': 'telegram92.p.rapidapi.com',
        'x-rapidapi-key': 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e' // Replace with your RapidAPI key
      }
    }).pipe(
      catchError(this.handleError)
    );
  }

  // Combined method to get both user info and discussion info
  getTelegramUserAndDiscussion(userHandle: string, peer: string, msgId: number): Observable<any> {
    return forkJoin({
      userInfo: this.getTelegramUserInfo(userHandle),
      discussionInfo: this.getTelegramDiscussion(peer, msgId)
    });
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
