import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TiktokService } from '../services/tiktok.service';

@Component({
  selector: 'app-tik-tok',
  templateUrl: './tik-tok.component.html',
  styleUrls: ['./tik-tok.component.scss']
})
export class TikTokComponent implements OnInit {
  errorMessage: string | null = null;
  user: any;
  fakeProbability: number = 0;
  probabilityMessage: string = '';

  constructor(private tiktokService: TiktokService) { }

  ngOnInit(): void {}

  getUserInfo(username: string): void {
    this.tiktokService.getTikTokProfile(username).subscribe(
      response => {
        console.log('User data:', response.data.user); 
        this.user = response.data.user; 
        this.errorMessage = null;
        this.calculateFakeProbability();
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = 'An error occurred. Please try again later.';
      }
    );
  }
  

  calculateFakeProbability(): void {
    let probability = 0;
    let usedCriteria = '';

    // Check for numbers in username
    if (this.user.username) {
      const username = this.user.username;
      const numberCount = (username.match(/\d/g) || []).length;
      if (numberCount > 4) {
        probability += 50;
        usedCriteria += 'Username contains more than four numbers. ';
      }
    } else {
      usedCriteria += 'Username is not available. ';
    }

    // Check account age, if available
    if (this.user.created_time) {
      const createdAt = new Date(this.user.created_time * 1000); // Assuming created_time is in Unix timestamp format
      const now = new Date();
      const ageInDays = Math.floor((now.getTime() - createdAt.getTime()) / (1000 * 3600 * 24));
      if (ageInDays < 365) {
        probability += 50;
        usedCriteria += 'Account age is less than 1 year. ';
      }
    } else {
      usedCriteria += 'Account age is not available. ';
    }

    this.fakeProbability = probability;
    this.probabilityMessage = `Probability of ${this.user.username ? this.user.username : 'this account'} being a fake account: ${this.fakeProbability}%. Criteria used: ${usedCriteria}`;
  }
}
